import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';

import {TransferHttpService} from '@core/modules/transfer-http/transfer-http.service';
import {buildURLParams} from '@helpers/build-url-params';
import {httpParamsFromObject} from '@helpers/http-params';
import {
  BoatFleetAddPayload,
  BoatFleetBoatAddPayload,
  BoatFleetDeletePayload,
  BoatFleetEditPayload,
  BoatFleets,
  BoatFleetsPayload,
  BoatFleetUserAddPayload,
  BoatFleetUserDeletePayload,
  BoatFleetUserEditPayload,
  BoatFleetUserInfoDto,
  BoatFleetUserPayload,
  BoatSettingBoatIdPayload,
  BoatsFleetBoatsPayload,
  BoatsFleetDeleteBoatPayload,
  BoatsFleetFleetBoatShortItemDto,
  BoatsFleetIdPayload,
  BoatsFleetUsersDto,
  BoatsFleetUsersPayload,
  BoatsShortItemDto,
  FleetBoatCheckPermissionsDto,
  FleetBoatCheckPermissionsPayload,
  FleetsDto,
  FleetShortItemDto,
  FleetsListDto,
  UserFleetPermission,
  UserFleetPermissionDto,
} from '@models/boats-fleet';
import {ResponseDto} from '@models/response';

@Injectable({
  providedIn: 'root',
})
export class FleetManagementApiService {
  private apiUrl = 'api/fleet-management';

  constructor(private readonly http: TransferHttpService) {
  }

  getFleetsList(
    page: number,
    limit = 5,
  ): Observable<FleetsListDto> {
    const params = httpParamsFromObject({page, limit});
    return this.http
      .get<ResponseDto<FleetsListDto>>(`${this.apiUrl}/get-fleet-list`, {
      params,
    })
      .pipe(map(response => response.data));
  }

  addFleet(payload: BoatFleetAddPayload): Observable<ResponseDto> {
    return this.http.post(`${this.apiUrl}/add-fleet`, payload);
  }

  editFleet(payload: BoatFleetEditPayload): Observable<ResponseDto> {
    return this.http.put(`${this.apiUrl}/edit-fleet`, payload);
  }

  deleteFleet(fleet: number): Observable<ResponseDto> {
    const params = httpParamsFromObject({fleet});
    return this.http.delete(`${this.apiUrl}/delete-fleet`, {params});
  }

  getFleetUsers(
    payload: BoatsFleetUsersPayload,
  ): Observable<BoatsFleetUsersDto> {
    const params = httpParamsFromObject(payload);
    return this.http
      .get<ResponseDto<BoatsFleetUsersDto>>(`${this.apiUrl}/get-fleet-users`, {
      params,
    })
      .pipe(map(res => res.data));
  }

  getFleetUser(payload: BoatFleetUserPayload): Observable<BoatFleetUserInfoDto> {
    const params = httpParamsFromObject(payload);
    return this.http.get<ResponseDto<BoatFleetUserInfoDto>>(`${this.apiUrl}/get-fleet-user`, {params}).pipe(
      map(res => res.data),
    );
  }

  addFleetUser(
    payload: BoatFleetUserAddPayload,
  ): Observable<ResponseDto> {
    return this.http.post(`${this.apiUrl}/add-fleet-user`, payload);
  }

  editFleetUser(
    payload: BoatFleetUserEditPayload,
  ): Observable<ResponseDto> {
    return this.http.put(`${this.apiUrl}/edit-fleet-user`, payload);
  }

  deleteFleetUser(
    payload: BoatFleetUserDeletePayload,
  ): Observable<ResponseDto> {
    const params = httpParamsFromObject(payload);
    return this.http.delete(`${this.apiUrl}/delete-fleet-user`, {params});
  }

  getFleetBoats(
    payload: BoatsFleetBoatsPayload,
  ): Observable<BoatsFleetFleetBoatShortItemDto> {
    const params = httpParamsFromObject(payload);
    return this.http
      .get<ResponseDto<BoatsFleetFleetBoatShortItemDto>>(
        `${this.apiUrl}/get-fleet-boat-list`,
        {params},
    )
      .pipe(map(res => res.data));
  }

  deleteFleetBoat(
    payload: BoatsFleetDeleteBoatPayload,
  ): Observable<ResponseDto> {
    const params = httpParamsFromObject(payload);
    return this.http.delete(`${this.apiUrl}/delete-fleet-boat`, {params});
  }

  addFleetBoat(
    payload: BoatFleetBoatAddPayload,
  ): Observable<ResponseDto> {
    return this.http.post(`${this.apiUrl}/add-fleet-boat`, payload);
  }

  getAdminBoats(payload: BoatsFleetIdPayload): Observable<BoatsShortItemDto[]> {
    const params = httpParamsFromObject(payload);
    return this.http
      .get<ResponseDto<BoatsShortItemDto[]>>(
        `${this.apiUrl}/get-fleet-admin-boat-list`,
        {params},
    )
      .pipe(map(res => res.data));
  }

  acceptInvitation(fleetInvitation: number): Observable<ResponseDto> {
    return this.http.post(`${this.apiUrl}/accept-fleet-user-invitation`, {fleetInvitation});
  }

  rejectInvitation(fleetInvitation: number): Observable<ResponseDto> {
    return this.http.post(`${this.apiUrl}/decline-fleet-user-invitation`, {fleetInvitation});
  }

  emailAcceptInvitation(token: string): Observable<ResponseDto> {
    return this.http.post(`${this.apiUrl}/accept-fleet-invitation`, {token});
  }

  emailRejectInvitation(token: string): Observable<ResponseDto> {
    return this.http.post(`${this.apiUrl}/decline-fleet-invitation`, {token});
  }

  getUserFleetPermissions(): Observable<UserFleetPermissionDto[]> {
    return this.http.get<ResponseDto<UserFleetPermissionDto[]>>(`${this.apiUrl}/get-fleet-permissions`)
      .pipe(map(res => res.data));
  }

  getFleetsShortList(permissions: UserFleetPermission[] = []): Observable<FleetShortItemDto[]> {
    const params = buildURLParams(permissions, false, 'permissions');
    return this.http.get<ResponseDto<FleetShortItemDto[]>>(
      `${this.apiUrl}/get-short-fleet-list`,
      {params},
    ).pipe(map(res => res.data));
  }

  getBoatSettingFleets(payload: BoatFleetsPayload): Observable<BoatFleets> {
    const params = httpParamsFromObject(payload);
    return this.http.get<ResponseDto<BoatFleets>>(`${this.apiUrl}/get-boat-fleet-list`, {params})
      .pipe(
        map(response => response.data),
      );
  }

  deleteBoatSettingFleet(payload: BoatFleetDeletePayload): Observable<ResponseDto> {
    const params = httpParamsFromObject(payload);
    return this.http.delete(`${this.apiUrl}/delete-fleet-boat`, {params});
  }

  getSettingAdminBoats(payload: BoatSettingBoatIdPayload): Observable<FleetsDto[]> {
    const params = httpParamsFromObject(payload);
    return this.http
      .get<ResponseDto<FleetsDto[]>>(
        `${this.apiUrl}/get-boat-admin-fleet-list`,
        {params},
    )
      .pipe(map(res => res.data));
  }

  checkBoatAccess(payload: FleetBoatCheckPermissionsPayload): Observable<FleetBoatCheckPermissionsDto> {
    const params = httpParamsFromObject(payload);
    return this.http
      .get<ResponseDto<FleetBoatCheckPermissionsDto>>(
        `${this.apiUrl}/check-access`,
        {params},
    )
      .pipe(map(res => res.data));
  }
}
